import React from 'react'
import '../styles/loader.css'

const Loader = () => {
    return (
        <div className="loading">Loading&#8230;</div>
    )
}

export default Loader;
